import React, { useState, useEffect, useRef } from "react";
import { Bar, Pie, Line, Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  PointElement,
  RadialLinearScale,
} from "chart.js";
import axios from "axios";
import styled from "styled-components";
import { BorderBottomOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Card, Col, Input, Row, Space, Table } from "antd";
import Statistic from "antd/es/statistic/Statistic";
import { useLocation } from "react-router-dom";
import BreadCrumbs from "../BreadCrumbs";

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  PointElement,
  RadialLinearScale
);

// Styled Components
const AnalyserWrapper = styled.div`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

const AnalyserContainer = styled.div`
  border: 1px solid rgb(43, 46, 74);
  border-radius: 8px;
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  box-sizing: border-box;
`;

const SelectContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;

  select {
    padding: 8px;
    min-width: 200px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }

  label {
    font-weight: 500;
  }
`;

const ChartsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin-top: 20px;
`;

const ChartContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const LoadingSpinner = styled.div`
  align-self: center;
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid rgb(43, 46, 74);
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const ErrorMessage = styled.div`
  color: #f44336;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  background-color: #ffebee;
`;

const ViewAnalysis = (props) => {
  const [formTitles, setFormTitles] = useState([]);
  const [formId, setFormId] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [emailList, setEmailList] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [chartData, setChartData] = useState(null);
  const [pieChartData, setPieChartData] = useState(null);
  const [color, setColor] = useState("");
  const [analyser, setAnalyser] = useState("");
  const [imageAnalyser, setImageAnalyser] = useState("");
  const [videoAnalyser, setVideoAnalyser] = useState("");
  const [audioAnalyser, setAudioAnalyser] = useState("");
  const [formAnalysisData, setFormAnalysisData] = useState(null);
  const [isIndividual, setIsIndividual] = useState(false);
  const [selectedAnalysis, setSelectedAnalysis] = useState("");
  const [formTitle, setFormTitle] = useState("");
  const [submitedBy, setsubmitedBy] = useState("");
  const [chartLabels, setChartLabels] = useState([
    "Angry",
    "Disgust",
    "Fear",
    "Happy",
    "Sad",
  ]);
  const [chartBackground, setChartBackground] = useState([
    "#4caf50",
    "#f44336",
    "#ff9800",
    "#9c27b0",
    "#03a9f4",
  ]);
  const [pieChartBackground, setPieChartBackground] = useState([
    "#4caf50",
    "#8BC34A",
    "#f44336",
    "#673AB7",
    "#ff9800",
    "#FFC107",
    "#9c27b0",
    "#009688",
    "#03A9F4",
    "#E6E6FA",
  ]);
  const [sentiment, setSentiment] = useState("");
  const [audioLength, setAudioLength] = useState("");
  const [hoveredCard, setHoveredCard] = useState(null);
  const [allKPIData, setAllKPIData] = useState([]);
  const [videoKPIData, setVideoKPIData] = useState([]);
  const [imageKPIData, setImageKPIData] = useState([]);
  const [audioKPIData, setAudioKPIData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const location = useLocation();
  useEffect(() => {
    setsubmitedBy(location.state.submitedBy);
    fetchSurveyForm(location.state.email);
  }, []);

  async function fetchSurveyForm(email) {
    setChartData(null);
    setAnalyser("");
    setImageKPIData([]);
    setVideoKPIData([]);
    setAudioKPIData([]);
    setImageAnalyser("");
    setVideoAnalyser("");
    setAudioAnalyser("");
    console.log("email.id:", email);
    let filterData = {};
    let transformedData;
    setSelectedEmail(email);
    if (email) {
      setLoading(true);
      setError("");
      try {
        const response = await axios.get(
          `https://puebem38qc.execute-api.ap-south-1.amazonaws.com/dev/survey-analysis`
        );
        // console.log("response.data:", response.data);
        let data = response?.data?.body;
        filterData = data.filter((body) => body.id === email);
        transformedData = filterData.map((item) => ({
          ...item,
          image_kpi: item.image_kpi
            ? Object.keys(item.image_kpi).map((fileName) => ({
                filename: fileName,
                ...item.image_kpi[fileName],
              }))
            : null,
          audio_kpi: item.audio_kpi
            ? Object.keys(item.audio_kpi).map((fileName) => ({
                filename: fileName,
                ...item.audio_kpi[fileName],
              }))
            : null,
          video_kpi: item.video_kpi
            ? Object.keys(item.video_kpi).map((fileName) => ({
                filename: fileName,
                ...item.video_kpi[fileName],
              }))
            : null,
        }));
        setAllKPIData(filterData[0]);
      } catch (error) {
        console.error("Error fetching survey form:", error);
        setError("Failed to fetch survey forms");
      } finally {
        setLoading(false);
      }
      setLoading(true);
      setError("");
      try {
        console.log("transformedData[0]:", transformedData[0]);
        if (transformedData[0].image_kpi) {
          setImageAnalyser("Image");
          setImageKPIData(transformedData[0].image_kpi);
          console.log("image_kpi:", transformedData[0].image_kpi);
        }
        if (transformedData[0].audio_kpi) {
          setAudioAnalyser("Audio");
          setAudioKPIData(transformedData[0].audio_kpi);
          console.log("audio_kpi:", transformedData[0].audio_kpi);
        }
        if (transformedData[0].video_kpi) {
          setVideoAnalyser("Video");
          setVideoKPIData(transformedData[0].video_kpi);

          // console.log("---------line 301 ");
        }
        // const sentimentData = videoKPIdata.average_emotion_scores;
      } catch (error) {
        console.error("Error fetching sentiment data:", error);
        setError("Failed to fetch sentiment data");
      } finally {
        setLoading(false);
      }
    }
  }
  const getSentimentColor = (sentiment) => {
    const sentimentMap = {
      'POSITIVE': {
        background: "rgb(16, 185, 129 )",
        hover: '#059669',
        light: '#D1FAE5',
        fontSize:'1px'
      },
      'NEGATIVE': {
        background: 'rgb(239, 68, 68 )',
        hover: '#DC2626',
        light: '#FEE2E2'
      },
      'NEUTRAL': {
        background: 'rgb(245, 158, 11)',
        hover: '#D97706',
        light: '#FEF3C7'
      }
    };
    return sentimentMap[sentiment.toUpperCase()] || {
      background: '#6B7280',
      hover: '#4B5563',
      light: '#F3F4F6'
    };
  };
  const getSentimentBadgeStyle = (sentiment) => ({
    display: 'inline-block',
    padding: '8px 16px',
    borderRadius: '20px',
    backgroundColor: getSentimentColor(sentiment).background,
    color: 'white',
    fontWeight: '600',
    fontSize: '12px',
    transition: 'all 0.2s ease',
    cursor: 'default',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    ':hover': {
      backgroundColor: getSentimentColor(sentiment).hover,
      transform: 'translateY(-1px)',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
    }
  });
  const getChartData = (data) => {
    return [
      data.average_emotion_scores?.angry,
      data.average_emotion_scores?.disgust,
      data.average_emotion_scores?.fear,
      data.average_emotion_scores?.happy,
      data.average_emotion_scores?.sad,
    ];
  };
  const getPieChartData = (data) => {
    return (data = Object.values(data.frame_analysis).map(
      (frame) => frame.emotion_score
    ));
  };
  const getPieChartLabels = (data) => {
    const emotionArray = Object.keys(data.frame_analysis).map((frame) => {
      const { dominant_emotion } = data.frame_analysis[frame];
      return `${frame} ${dominant_emotion}`;
    });
    return emotionArray;
  };

  const getChartOptions = (title) => ({
    responsive: true,
    plugins: {
      legend: { position: "top" },
      title: {
        display: true,
        text: title,
        font: { size: 16 },
      },
    },
  });
  const getCardStyle = (index) => ({
    borderRadius: '16px',
    boxShadow: hoveredCard === index 
      ? '0 8px 16px rgba(0, 0, 0, 0.1)' 
      : '0 2px 8px rgba(0, 0, 0, 0.05)',
    border: '1px solid #E5E7EB',
    transition: 'all 0.3s ease',
    backgroundColor: hoveredCard === index ? '#FFFFFF' : '#FAFAFA',
    transform: hoveredCard === index ? 'translateY(-2px)' : 'none'
  });

  return (
    <>
       <div style={{
      backgroundColor: '#F3F4F6',
      minHeight: '100vh',
      padding: '24px'
    }}>
      <div style={{
        maxWidth: '1400px',
        margin: '0 auto'
      }}>
        {/* Breadcrumbs */}
        <div style={{ marginBottom: '24px' }}>
          <BreadCrumbs location={props.location} />
        </div>

        {/* Header Section */}
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '32px',
          background: "#6a1b9a",
          padding: '24px 32px',
          borderRadius: '16px',
          color: 'white'
        }}>
          <h2 style={{
            fontSize: '18px',
            fontWeight: '600',
            margin: 0,
            textShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
          }}>Analysis Dashboard</h2>
          <div style={{
            fontSize: '16px',
            fontWeight: '500',
            opacity: 0.9
          }}>
            Submitted By: {submitedBy}
          </div>
        </div>

        {/* Error Message */}
        {error && (
          <div style={{
            padding: '16px',
            backgroundColor: '#FEE2E2',
            border: '1px solid #EF4444',
            borderRadius: '8px',
            color: '#B91C1C',
            marginBottom: '24px'
          }}>
            {error}
          </div>
        )}

        {/* Loading State */}
        {loading && (
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '48px 0',
            backgroundColor: 'white',
            borderRadius: '12px',
            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)'
          }}>
            <LoadingSpinner />
            <div style={{
              marginTop: '16px',
              color: '#2B2E48',
              fontWeight: '500'
            }}>
              Loading...
            </div>
          </div>
        )}

        {/* Analysis Cards */}
        <div style={{ display: 'flex', gap: '24px', flexDirection: 'column' }}>
          {/* Image Analysis */}
          {imageAnalyser === "Image" && (
            <Card
              title={<div style={{
                fontSize: '18px',
                fontWeight: '600',
                color: '#1F2937'
              }}>Image Analysis</div>}
              style={{
                borderRadius: '12px',
                boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                border: '1px solid #E5E7EB'
              }}
            >
              {imageKPIData.map((data, index) => (
                <div key={index} style={{
                  padding: index > 0 ? '16px 0 0 0' : 0,
                  borderTop: index > 0 ? '1px solid #E5E7EB' : 'none'
                }}>
                  <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gap: '24px',
                    margin: '16px 0'
                  }}>
                    <div>
                      <div style={{ color: '#6B7280', marginBottom: '4px' }}>Filename</div>
                      <div style={{ fontWeight: '500' }}>{data.filename}</div>
                    </div>
                    <div>
                      <div style={{ color: '#6B7280', marginBottom: '4px' }}>Overall Sentiment</div>
                      <div style={getSentimentBadgeStyle(data.overall_sentiment)}>
                        {data.overall_sentiment.toUpperCase()}
                      </div>
                    </div>
                    <div>
                      <div style={{ color: '#6B7280', marginBottom: '4px' }}>Overall Emotion</div>
                      <div style={{ fontWeight: '500' }}>{data.overall_emotion.toUpperCase()}</div>
                    </div>
                  </div>
                </div>
              ))}
            </Card>
          )}

          {/* Audio Analysis */}
          {audioAnalyser === "Audio" && (
            <Card
              title={<div style={{
                fontSize: '18px',
                fontWeight: '600',
                color: '#1F2937'
              }}>Audio Analysis</div>}
              style={{
                borderRadius: '12px',
                boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                border: '1px solid #E5E7EB'
              }}
            >
              {audioKPIData.map((data, index) => (
                <div key={index} style={{
                  padding: index > 0 ? '16px 0 0 0' : 0,
                  borderTop: index > 0 ? '1px solid #E5E7EB' : 'none'
                }}>
                  <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gap: '24px',
                    margin: '16px 0'
                  }}>
                    <div>
                      <div style={{ color: '#6B7280', marginBottom: '4px' }}>Filename</div>
                      <div style={{ fontWeight: '500' }}>{data.filename}</div>
                    </div>
                    <div>
                      <div style={{ color: '#6B7280', marginBottom: '4px' }}>Overall Sentiment</div>
                      <div style={getSentimentBadgeStyle(data.overall_sentiment)}>
                        {data.overall_sentiment.toUpperCase()}
                      </div>
                    </div>
                    <div>
                      <div style={{ color: '#6B7280', marginBottom: '4px' }}>Audio Length</div>
                      <div style={{ fontWeight: '500' }}>{data.audio_length}</div>
                    </div>
                  </div>
                </div>
              ))}
            </Card>
          )}

          {/* Video Analysis */}
          {videoAnalyser === "Video" && (
            <Card
              title={<div style={{
                fontSize: '18px',
                fontWeight: '600',
                color: '#1F2937'
              }}>Video Analysis</div>}
              style={{
                borderRadius: '12px',
                boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                border: '1px solid #E5E7EB'
              }}
            >
              {videoKPIData.map((data, index) => (
                <div key={index} style={{
                  padding: index > 0 ? '16px 0 0 0' : 0,
                  borderTop: index > 0 ? '1px solid #E5E7EB' : 'none'
                }}>
                  <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: '24px',
                    margin: '16px 0'
                  }}>
                    <div>
                      <div style={{ color: '#6B7280', marginBottom: '4px' }}>Filename</div>
                      <div style={{ fontWeight: '500' }}>{data.filename}</div>
                    </div>
                    <div>
                      <div style={{ color: '#6B7280', marginBottom: '4px' }}>Overall Sentiment</div>
                      <div style={getSentimentBadgeStyle(data.overall_sentiment)}>
                        {data.overall_sentiment.toUpperCase()}
                      </div>
                    </div>
                  </div>

                  <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: '24px',
                    marginTop: '24px'
                  }}>
                    <div style={{
                      backgroundColor: 'white',
                      padding: '24px',
                      borderRadius: '8px',
                      border: '1px solid #E5E7EB'
                    }}>
                      <Bar
                        data={{
                          labels: chartLabels,
                          datasets: [{
                            label: "Sentiment Distribution",
                            data: getChartData(data),
                            backgroundColor: chartBackground,
                          }]
                        }}
                        options={{
                          ...getChartOptions("Sentiment Analysis - Bar Chart"),
                          maintainAspectRatio: false
                        }}
                        style={{ height: '300px' }}
                      />
                    </div>
                    <div style={{
                      backgroundColor: 'white',
                      padding: '24px',
                      borderRadius: '8px',
                      border: '1px solid #E5E7EB'
                    }}>
                      <Pie
                        data={{
                          labels: getPieChartLabels(data),
                          datasets: [{
                            data: getPieChartData(data),
                            backgroundColor: pieChartBackground,
                          }]
                        }}
                        options={{
                          ...getChartOptions("Sentiment Distribution - Pie Chart"),
                          maintainAspectRatio: false
                        }}
                        style={{ height: '300px',width:'30px' }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </Card>
          )}
        </div>
      </div>
    </div>
              {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
            </>
          )}
      //   </AnalyserContainer>
      // </AnalyserWrapper>
    
//   );
// };
export default ViewAnalysis;
