import React, { useEffect, useState } from "react";
import axios from "axios";
 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShareAlt,
  faCopy,
  faTrash,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Upload,
  Model,
  Icon,
  Tag,
  message,
  Modal,
  Spin,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { CopyOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import BreadCrumbs from "../BreadCrumbs";
import styled from '@emotion/styled';
const ViewForms = (props) => {
  const [forms, setForms] = useState([]);
  // const [emails, setEmails] = useState([]);
  const [emails, setEmails] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [formTitle, setFormTitle] = useState("");
  const [formURL, setFormURL] = useState("http://dummyLink/view-forms");
  const [shareDisable, setShareDisable] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
 
  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";
  const openMessage = () => {
    messageApi.open({
      key,
      type: "loading",
      content: "Copying...",
    });
    setTimeout(() => {
      messageApi.open({
        key,
        type: "success",
        content: "Copied!",
        duration: 2,
      });
    }, 1000);
  };
 
  // const handleSearch = (value) => {
  //   const newEmail = value.trim();
  //   if (newEmail) {
  //     setEmails([...emails, newEmail]);
  //   }
  // };
 
  const antIcon = (
    <>
      <LoadingOutlined style={{ fontSize: 24 }} spin />
    </>
  );
  const copyLinkToClipboard = () => {
    // inputRef.current.select();
    openMessage();
    navigator.clipboard.writeText(formURL);
  };
 
  const [shareForm] = Form.useForm();
  shareForm.setFieldsValue({
    title: formTitle,
  });
 
  const [isModalOpen, setIsModalOpen] = useState(false);
 
  const showModal = () => {
    setIsModalOpen(!isModalOpen);
  };
 
  const handleOk = async (values) => {
    setModalLoading(true);
    // messageApi.open({
    //   key,
    //   type: "loading",
    //   content: "Sending email...",
    // });
    // setTimeout(() => {
    //   messageApi.open({
    //     key,
    //     type: "success",
    //     content: "Copied!",
    //     duration: 2,
    //   });
    // }, 1000);
    console.log("title:", formTitle);
    console.log("emails:", values);
    const payload = {
      form_id: selectedValue,
      emails: emails,
    };
    await axios
      .post(
        "https://puebem38qc.execute-api.ap-south-1.amazonaws.com/dev/send-survey",
        payload
      )
      .then((res) => {
        console.log(res);
        setEmails([]);
        messageApi.open({
          key,
          type: "success",
          content: "Email sent successfully!",
          duration: 2,
        });
      })
      .catch((err) => {
        messageApi.open({
          key,
          type: "error",
          content: "Something went wrong!",
          duration: 2,
        });
      });
    showModal();
 
    setModalLoading(false);
  };
 
  const handleCancel = () => {
    setIsModalOpen(!isModalOpen);
  };
 
  // const handleSubmit = (e) => {
  //   e.preventDefault();
 
  //   // Add your email sending logic here, e.g., using a library like `react-emailjs`
  //   // console.log(`Sending email to: ${to}`);
  //   // console.log(`Subject: ${subject}`);
  //   // console.log(`Message: ${message}`);
  //   // axios
  //   //   .post(
  //   //     "https://puebem38qc.execute-api.ap-south-1.amazonaws.com/dev/send-survey",
  //   //     { title: subject, email: [to] }
  //   //   )
  //   //   .then((res) => {
  //   //     console.log(res);
  //   //   })
  //   //   .catch((err) => console.log(err));
  // };
 
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
 
  const handleInputConfirm = () => {
    if (
      inputValue &&
      validateEmail(inputValue) &&
      !emails.includes(inputValue)
    ) {
      setEmails([...emails, inputValue]);
      setInputValue("");
      shareForm.setFieldsValue({
        emails: "",
        title: formTitle,
      });
    } else {
      messageApi.open({
        type: "error",
        content: "Invalid email",
        duration: 2,
      });
    }
  };
 
  const handleRemoveEmail = (removedEmail) => {
    setEmails(emails.filter((email) => email !== removedEmail));
  };
 
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
 
  async function fetchSurveyForm() {
    try {
      const response = await axios.get(
        "https://qyow74zw35.execute-api.ap-south-1.amazonaws.com/dev/survey-form?id=null"
      );
      console.log("response data", response.data);
      setSurveyData(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching survey form:", error);
      throw error; // Re-throw the error to handle it appropriately in the calling component
    }
  }
  const [surveyData, setSurveyData] = useState(null);
 
  useEffect(() => {
    fetchSurveyForm();
 
    // .catch(error => setError(error));
  }, []);
 
  function fun(selectedValue) {
    console.log("Hello, you selected:", selectedValue);
  }
 
  async function getFormDataByID(item) {
    try {
      const url = `https://qyow74zw35.execute-api.ap-south-1.amazonaws.com/dev/survey-form?id=${item}`;
      axios
        .get(url) // Use params object for GET requests
        .then((response) => {
          console.log(response.data[0].title);
          setForms(response.data);
          setFormTitle(response.data[0].title);
          setShareDisable(true);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (error) {
      console.error("Error fetching survey form:", error);
      throw error; // Re-throw the error to handle it appropriately in the calling component
    }
  }
 
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const handleDeleteClick = async (id) => {
    try {
      console.log("deleteFormField id:", id);
      // await deleteFormField(id);
      setForms(forms.filter((form) => form.id !== id));
      alert("Form deleted successfully!");
    } catch (error) {
      alert("Failed to delete form.");
    }
  };
 
  useEffect(() => {
    const loadForms = async () => {
      try {
        const item = localStorage.getItem("form-builder");
        setForms(item ? [JSON.parse(item)] : null);
      } catch (error) {}
    };
 
    // loadForms();
  }, []);
 
  const renderFormField = (field) => {
    const commonStyles = {
      width: "100%",
      padding: "8px",
      margin: "8px 0",
      border: "1px solid #ccc",
      borderRadius: "4px",
      fontSize: "16px",
      color: "#495057",
 
    };
 
    switch (field.field_type) {
      case "text":
        return (
          <Form.Item label={field.label} name={field.label}>
            <Input
              placeholder={field.placeholder}
              style={{
                color: '#ffffff',
                backgroundColor: '#ffffff',
                borderColor: '#ffffff',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                cursor: 'not-allowed',
                opacity: 1,
              }}
            />
          </Form.Item>
        );
      case "email":
        return (
          <Form.Item name={field.label} label={field.label}>
            <Input placeholder={field.placeholder}
            style={{
              color: '#ffffff',
              backgroundColor: '#ffffff',
              borderColor: '#ffffff',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              cursor: 'not-allowed',
              opacity: 1,
            }} />
          </Form.Item>
        );
      case "number":
        return (
          <Form.Item label={field.label} name={field.label}>
            <InputNumber
              placeholder={field.placeholder}
              style={{
                width: "100%",
                  color: '#ffffff',
                  backgroundColor: '#ffffff',
                  borderColor: '#ffffff',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  cursor: 'not-allowed',
                  opacity: 1,
               
              }}
            />
          </Form.Item>
        );
      case "password":
        return (
          <Form.Item label={field.label} name={field.label}>
            <Input.Password placeholder={field.placeholder} />
          </Form.Item>
        );
      case "checkbox":
        return (
          <Form.Item label={field.label}>
            <Checkbox.Group>
              {field.options.map((option, index) => (
                <Checkbox value={option}>{option}</Checkbox>
                // <Radio value={option}>{option} </Radio>
              ))}
            </Checkbox.Group>
          </Form.Item>
        );
      case "radio":
        return (
          <Form.Item label={field.label}>
            <Radio.Group>
              {field.options.map((option, index) => (
                <Radio value={option}>{option} </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        );
        case "dropdown":
          return (
            <Form.Item label={field.label} name={field.label}>
              <Select
                placeholder={field.placeholder}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={field.options.map((option) => ({
                  value: option,
                  label: option,
                }))}
                style={{
                  color: '#333', // Dark text for better readability
                  backgroundColor: '#ffffff', // White background
                  borderColor: '#ddd', // Light border
                  borderRadius: '8px', // Rounded corners
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle shadow
                  cursor: 'not-allowed', // Not allowed cursor
                  opacity: 1, // Full opacity
                }}
                dropdownStyle={{
                  backgroundColor: '#ffffff', // White dropdown background
                }}
              />
            </Form.Item>
          );
      case "date":
        return (
          <Form.Item label={field.label} name={field.label}>
            <DatePicker />
          </Form.Item>
        );
      case "textarea":
        return (
          <Form.Item label={field.label}>
            <TextArea rows={4} placeholder={field.placeholder} />
          </Form.Item>
        );
      case "file":
  return (
    <Form.Item
      label={field.label}
      name={field.label}
      valuePropName="fileList"
      getValueFromEvent={normFile}
    >
      <Upload action="/upload.do" listType="picture-card" maxCount={1}>
        <button
          style={{
            border: '2px solid rgb(205 187 238)', // Solid border in purple
            backgroundColor: '#ffffff', // White background
            borderRadius: '8px', // Rounded corners
            padding: '20px', // Padding for better spacing
            cursor: 'pointer', // Pointer cursor for interactivity
            transition: 'background-color 0.3s, border-color 0.3s', // Smooth transition
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
          }}
          type="button"
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = '#f0f0f0'; // Light gray on hover
            e.currentTarget.style.borderColor = '#5a34a1'; // Darken border on hover
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = '#ffffff'; // Reset background
            e.currentTarget.style.borderColor = '#6f42c1'; // Reset border
          }}
        >
          <PlusOutlined style={{ fontSize: '24px', color: '#6f42c1' }} />
          <div style={{ marginTop: 5, color: '#6f42c1', fontWeight: '500' }}>
            Upload
          </div>
        </button>
      </Upload>
    </Form.Item>
  );
        case "button":
          return (
            <Form.Item
              wrapperCol={{
                offset: 6,
                span: 16,
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: '#6f42c1', // Purple background
                  borderColor: '#6f42c1', // Purple border
                  color: '#ffffff', // White text color
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Optional shadow for depth
                }}
              >
                {field.placeholder}
              </Button>
            </Form.Item>
          );
      default:
        return <div>Unknown Form Item</div>;
    }
  };
 
  // const generateEmbedCode = (slug) => {
  //   const embedUrl = `${API_URL}/api/v1/forms/${slug}`;
  //   return `<iframe src="${embedUrl}" width="100%" height="500px" frameborder="0"></iframe>`;
  // };
 
  const handleShareClick = (slug) => {
    console.log("slug:", slug);
    // const embedCode = generateEmbedCode(slug);
    // navigator.clipboard.writeText(embedCode);
    alert("Embed code copied to clipboard!");
  };
 
  const generateHtmlString = (formFields) => {
    return formFields
      .map((field) => {
        switch (field.field_type) {
          // {
          //   case "text":
          //   case "email":
          //   case "number":
          //   case "password":
          //     return `<input type="${field.field_type}" placeholder="${field.placeholder}" disabled />`;
          //   case "checkbox":
          //   case "radio":
          //     return `<label><input type="${field.field_type}" disabled /> ${field.label}</label>`;
          //   case "dropdown":
          //     return `<select disabled>${field.options
          //       .map((option) => `<option>${option}</option>`)
          //       .join("")}</select>`;
          //   case "date":
          //     return `<input type="date" disabled />`;
          //   case "textarea":
          //     return `<textarea placeholder="${field.placeholder}" disabled></textarea>`;
          //   case "file":
          //     return `<input type="file" disabled />`;
          //   default:
          //     return `<div>Unknown field type</div>`;
          // }
          case "text":
            return `<Form.Item label="${field.label}" name="${field.label}">
                <Input placeholder="${field.placeholder}" />
              </Form.Item>`;
          case "email":
            return `
              <Form.Item name="${field.label}" label="${field.label}">
                <Input placeholder="${field.placeholder}" />
              </Form.Item>`;
          case "number":
            return `
              <Form.Item label="${field.label}" name="${field.label}">
                <InputNumber
                  placeholder="${field.placeholder}"
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>`;
          case "password":
            return `
              <Form.Item label="${field.label}" name="${field.label}">
                <Input.Password placeholder="${field.placeholder}" />
              </Form.Item>`;
          case "checkbox":
            return `
                <Form.Item label="${field.label}">
                    <Checkbox.Group>
                      ${field.options
                        .map(
                          (option, index) =>
                            `<Checkbox value=${option}>${option}</Checkbox>`
                        )
                        .join("")}
                    </Checkbox.Group>
                  </Form.Item>
          `;
          case "radio":
            return `
              <Form.Item label="${field.label}">
                <Radio.Group>
                  ${field.options
                    .map(
                      (option, index) =>
                        ` <Radio value=${option}>${option} </Radio>`
                    )
                    .join("")}
                </Radio.Group>
              </Form.Item>`;
          case "dropdown":
            return `
              <Form.Item label="${field.label}" name="${field.label}">
                <Select
                  placeholder="${field.placeholder}"
                  filterSort=${(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                      .join("")}
                  options=${field.options
                    .map((option, index) => ({
                      value: option,
                      label: option,
                    }))
                    .join("")}
                />
              </Form.Item>`;
          case "date":
            return `
              <Form.Item label="${field.label}" name="${field.label}">
                <DatePicker />
              </Form.Item>`;
          case "textarea":
            return `
              <Form.Item label="${field.label}">
                <TextArea rows={4} placeholder="${field.placeholder}" />
              </Form.Item>`;
          case "file":
            // return <input type="file" disabled={true} />;
            return `
              <Form.Item
                label="${field.label}"
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload action="/upload.do" listType="picture-card" maxCount={1}>
                  <button
                    style={{
                      border: 0,
                      background: "none",
                    }}
                    type="button"
                  >
                    <PlusOutlined />
                    <div
                      style={{
                        marginTop: 5,
                      }}
                    >
                      Upload
                    </div>
                  </button>
                </Upload>
              </Form.Item>`;
          case "button":
            return `
              <Form.Item
                wrapperCol={{
                  offset: 6,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit">
                  "${field.placeholder}"
                </Button>
              </Form.Item>
            `;
          default:
            return <div>Unknown Form Item</div>;
        }
      })
      .join("");
  };
 
  const handleCopyHtml = (formFields) => {
    let htmlString = generateHtmlString(formFields);
    const prefix = `<Form
    name="basic"
    layout="vertical"
    onFinish={onFinish}
    labelCol={{
          span: 2,
        }}
  >`;
 
    const suffix = `</Form>`;
    htmlString = prefix + htmlString + suffix;
    console.log("htmlString:", htmlString);
    navigator.clipboard.writeText(htmlString).then(() => {
      alert("HTML copied to clipboard!");
    });
  };
 
  const [selectedValue, setSelectedValue] = useState(null);
 
  const handleChange = (value) => {
    console.log("valuev", value);
    getFormDataByID(value);
    setSelectedValue(value);
  };
//yha pe view form heading ki css hai
 
const PageContainer = styled.div`
  display: flex;
  background-color: white;
  min-height: calc(100vh - 64px);
  padding: 24px;
`;
 
const MainContent = styled.div`
  flex-grow: 1;
  background: white;
  border-radius: 12px;
  border: 1px solid #e6e8f0;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
`;
 
const HeaderSection = styled.div`
  padding: 24px;
  border-bottom: 1px solid #e6e8f0;
`;
 
const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
 
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;
 
const Title = styled.h3`
  font-size: 24px;
  font-weight: 600;
  color: #2B2E48;
  margin: 0;
`;
 
const ActionButtons = styled.div`
  display: flex;
  gap: 12px;
 
  @media (max-width: 768px) {
    justify-content: stretch;
  }
`;
 
const StyledButton = styled.button`
  padding: 12px 20px;
  background-color: ${props => props.variant === 'primary' ? '#2B2E48' : '#28a745'};
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  transition: opacity 0.2s;
 
  &:hover {
    opacity: 0.9;
  }
 
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;
 
const FormContainer = styled.div`
  padding: 24px;
  max-width: 800px;
  margin: 0 auto;
`;
 
const FormField = styled.div`
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  border: 1px solid #e9ecef;
`;
 
  return (
    <>
      <BreadCrumbs location={props.location} />
      <div
  style={{
    display: "flex",
    backgroundColor: "#f5f5f5", // A light background like Google Forms
    justifyContent: "center", // Center the form horizontally
  }}
>
  {contextHolder}
  <div
    style={{
      padding: "32px", // Extra padding for a spacious feel
      flexGrow: 1,
      maxWidth: "900px", // Larger width for readability
      margin: "16px",
      border: "1px solid #e0e0e0", // Lighter border color
      borderRadius: "12px", // Rounded corners for a modern look
      backgroundColor: "#ffffff",
      boxShadow: "0 6px 25px rgba(0, 0, 0, 0.1)", // Softer box-shadow for elegance
    }}
  >
     <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: "#2B2E48",
        marginTop: "0px",
        marginLeft: "20px",
       // gap:"150px",
      }}
    >
   
      <h3 style={{
            margin: 0,
            color: "black",
            fontSize: "18px",
            textAlign: "center",
            fontWeight:'500',
          }}>View Form</h3>
      <Select
        placeholder="Select"
        style={{ minWidth: "200px" }}
        onChange={(value) => handleChange(value)}
      >
        {Array.isArray(surveyData) &&
          surveyData.map((item, index) => (
            <Select.Option key={index} value={item?.id}>
              {item?.title}
            </Select.Option>
          ))}
      </Select>
      {shareDisable && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <button
            onClick={showModal}
            style={{
              padding: "10px 20px",
              backgroundColor: "#663399", // Google blue color
              color: "white",
              border: "none",
              borderRadius: "30px", // Rounded button for a modern look
              cursor: "pointer",
              fontWeight: "500",
              transition: "background-color 0.3s ease, transform 0.3s ease",
              fontSize: "16px",
            }}
            onMouseEnter={(e) => (e.target.style.backgroundColor = "#4b2b85")}
            onMouseLeave={(e) => (e.target.style.backgroundColor = "#663399")}
            onMouseDown={(e) => (e.target.style.transform = "scale(0.98)")}
            onMouseUp={(e) => (e.target.style.transform = "scale(1)")}
          >
            <FontAwesomeIcon icon={faShare} style={{ marginRight: "8px" }} />
            Share
          </button>
          <button
            onClick={() => handleCopyHtml(forms[0].form_schema)}
            style={{
              padding: "10px 20px",
              backgroundColor: "#34a853", // Google green color
              color: "white",
              border: "none",
              borderRadius: "30px", // Rounded button for a modern look
              cursor: "pointer",
              fontWeight: "500",
              transition: "background-color 0.3s ease, transform 0.3s ease",
              fontSize: "16px",
            }}
            onMouseEnter={(e) => (e.target.style.backgroundColor = "#2c8c3b")}
            onMouseLeave={(e) => (e.target.style.backgroundColor = "#34a853")}
            onMouseDown={(e) => (e.target.style.transform = "scale(0.98)")}
            onMouseUp={(e) => (e.target.style.transform = "scale(1)")}
          >
            <FontAwesomeIcon icon={faCopy} style={{ marginRight: "8px" }} />
            Copy as HTML
          </button>
        </div>
      )}
    </div>
 
    {/* Share Form Modal */}
    <Modal
      title="Share Form"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width={450}
      style={{
        fontFamily: "Roboto, sans-serif",
      }}
    >
      <Spin indicator={antIcon} spinning={modalLoading} tip="Sending emails...">
        <Form
          form={shareForm}
          name="basic"
          style={{ maxWidth: "100%" }}
          onFinish={handleOk}
        >
          <Form.Item label="Form Title" name="title">
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="emails"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input
              type="text"
              placeholder="Enter email and press Enter"
              value={inputValue}
              onChange={handleInputChange}
              onPressEnter={handleInputConfirm}
            />
          </Form.Item>
          {emails.map((email, index) => (
            <Tag
              key={index}
              closable
              onClose={() => handleRemoveEmail(email)}
              style={{
                height: "25px",
                margin: "0 0 5px 0",
                backgroundColor: "#f1f3f4", // Light background for tags
                color: "#5f6368", // Dark text for readability
              }}
            >
              {email}
            </Tag>
          ))}
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Form.Item label={null}>
              <Button key="back" onClick={handleCancel}>
                Cancel
              </Button>
            </Form.Item>
            <Form.Item label={null}>
              <Button
                key="submit"
                disabled={emails.length === 0}
                onClick={handleOk}
                style={{
                  backgroundColor: "#663399", // Google blue color
                  color: "white",
                  border: "none",
                  borderRadius: "30px",
                  cursor: "pointer",
                  marginLeft: "20px",
                  fontWeight: "500",
                }}
              >
                <FontAwesomeIcon icon={faShare} style={{ marginRight: "8px" }} />
                Share
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </Modal>
 
    <hr style={{ borderTop: "1px solid #e0e0e0" }} />
    <Form
      layout="vertical"
      disabled={true}
      style={{
        width: "100%",
        margin: "20px auto",
        backgroundColor: "#ffffff",
        borderRadius: "16px", // More rounded edges
        boxShadow: "0 6px 20px rgba(0, 0, 0, 0.12)", // Soft shadow
        overflow: "hidden",
      }}
    >
      {forms?.map((form) => (
        <div key={form.id} style={{ marginBottom: "30px" }}>
          <div
            style={{
              padding: "15px",
              backgroundColor: "#6a1b9a", // Google purple color
              textAlign: "center",
              borderRadius: "12px",
              color: "#ffffff",
              fontSize: "1.2rem",
              fontWeight: "600",
            }}
          >
            {form.title}
          </div>
          <div style={{ padding: "20px" }}>
            {form.form_schema.map((field) => (
              <div key={field.id} style={{ marginBottom: "20px" }}>
                <label
                  style={{
                    display: "block",
                    marginBottom: "8px",
                    color: "#5f6368", // Dark text for labels
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  {/* {field.label} */}
                </label>
                {renderFormField(field, { placeholderStyle: { color: 'black' } })}
              </div>
            ))}
          </div>
          <div
            style={{
              padding: "15px",
              backgroundColor: "#f1f3f4", // Light background for footer
              textAlign: "center",
              color: "#5f6368", // Dark text color
              fontSize: "0.9rem",
              borderRadius: "0 0 12px 12px", // Rounded corners for footer
            }}
          >
            Form ID: {form.id}
          </div>
        </div>
      ))}
    </Form>
  </div>
</div>
 
 
 
    </>
  );
};
 
export default ViewForms;