import React, { useState, useEffect } from "react";
import { useDrop } from "react-dnd";
import { v4 as uuidv4 } from "uuid";
import Toolbox from "./ToolBox";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import FormField from "./FormField";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faCopy,
  faSave,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import TextArea from "antd/es/input/TextArea";
import styled from "styled-components";
import {
  PlusOutlined,
  SendOutlined,
  WechatFilled,
  CloseOutlined,
  WechatOutlined,
  WechatWorkFilled,
  WechatWorkOutlined,
} from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import axios from "axios";
import BreadCrumbs from "../BreadCrumbs";

const FormBuilder = (props) => {
  const [formItems, setFormItems] = useState([]);
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const [rotate, setRotate] = useState(false);
  const [previewMode, setPreviewMode] = useState(false);
  const [formTitle, setFormTitle] = useState("");
  const [chatbotInput, setChatbotInput] = useState("");
  const [isScrolled, setIsScrolled] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [scrollOffSet, setScrollOffSet] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [isToolboxOpen, setIsToolboxOpen] = useState(true);
  const [toolboxHeight, setToolboxHeight] = useState(0); 
  const [isTyping, setIsTyping] = useState(false);
  const handleToolboxToggle = (isOpen) => {
    setIsToolboxOpen(isOpen);
    setToolboxHeight(isOpen ? 300 : 0);
  };
  // const { createForm } = useCreateForm();

  const navigate = useNavigate();
  const [, drop] = useDrop(() => ({
    accept: [
      "text",
      "checkbox",
      "radio",
      "dropdown",
      "date",
      "textarea",
      "email",
      "number",
      "password",
      "file",
      "button",
    ],
    drop: (item) => {
      addItemToForm(item);
    },
  }));
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const LoadingSpinner = styled.div`
    align-self: center;
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid rgb(43, 46, 74);
    border-radius: 50%;
    animation: spin 1s linear infinite;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `;
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setScrollOffSet(offset);
      setIsScrolled(offset > 10); // Adjust threshold as needed
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const FormContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  
  .ant-form-item {
    margin-bottom: 0;
  }
`;

const FieldsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 24px;
 
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 40px;
  background: #f8fafc;
  border-radius: 12px;
  border: 2px dashed #e2e8f0;
  color: #64748b;
  font-size: 16px;
  margin: 20px 0;
`;

const FormFieldWrapper = styled.div`
  background: white;
  border-radius: 12px;
  border: 1px solid #e5e7eb;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &:hover {
    border-color: rgb(43, 46, 74);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background: rgb(43, 46, 74);
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover:before {
    opacity: 1;
  }
`;
  const ChatModal = styled.div`
  position: fixed;
  bottom: 70px;
  right: 450px;
  width: 400px;
  height: 500px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  display: flex;
  flex-direction: column;
  z-index: 1000;
  border: 1px solid #e8e8e8;
  transition: all 0.3s ease;
`;

const ChatHeader = styled.div`
  padding: 15px 20px;
  background: rgb(43, 46, 72);
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ChatArea = styled.div`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
`;
const TypingIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  background: white;
  padding: 12px;
  border-radius: 10px;
  width: fit-content;
  margin-top: 10px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
`;
const TypingDot = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: rgb(43, 46, 72);
  border-radius: 50%;
  margin: 0 3px;
  opacity: 0.6;

  &:nth-child(1) {
    animation: typing 1.4s infinite;
  }
  &:nth-child(2) {
    animation: typing 1.4s infinite 0.2s;
  }
  &:nth-child(3) {
    animation: typing 1.4s infinite 0.4s;
  }

  @keyframes typing {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
`;

const WelcomeMessage = styled.div`
  padding: 10px 15px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  margin-bottom: 10px;
`;

const ChatInputContainer = styled.div`
  padding: 15px;
  background: white;
  border-top: 1px solid #e8e8e8;
  display: flex;
  gap: 10px;
  align-items: center;
`;

const BouncingChatButton = styled(WechatFilled)`
  float: right;
  font-size: 50px;
  color: rgb(43, 46, 72);
  margin: -6px 150px 0px 0px;
  animation: bounce 1s infinite;

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
`;
//new chatbot css 
// const ChatbotContainer = styled.div`
//   position: fixed;
//   bottom: 20px;
//   right: 20px; // Adjusted for better positioning
//   display: flex;
//   align-items: flex-end;
//   gap: 16px;
//   z-index: 1000;
//   transition: all 0.3s ease; // Smooth transition for the container
// `;

// const ChatInputContainer = styled.div`
//   display: flex;
//   align-items: center;
//   background-color: white;
//   border-radius: 12px;
//   padding: 12px;
//   box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
//   transform-origin: bottom right;
//   opacity: ${props => props.isOpen ? 1 : 0};
//   transform: ${props => props.isOpen ? 'translateY(0) scale(1)' : 'translateY(20px) scale(0.9)'};
//   transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
//   width: ${props => props.isOpen ? '400px' : '0'};
//   margin-right: 16px;
//   border: 2px solid rgba(43, 46, 72, 0.1);
  
//   &:hover {
//     box-shadow: 0 12px 28px rgba(0, 0, 0, 0.2);
//   }
// `;

// const StyledInput = styled(Input)`
//   border: none;
//   background: rgba(43, 46, 72, 0.05);
//   border-radius: 8px;
//   padding: 12px;
//   margin-right: 12px;
//   transition: all 0.2s ease;

//   &:hover, &:focus {
//     background: rgba(43, 46, 72, 0.08);
//     box-shadow: none;
//   }

//   &::placeholder {
//     color: rgba(43, 46, 72, 0.6);
//   }
// `;

// const StyledSendButton = styled(Button)`
//   background: none;
//   border: none;
//   padding: 8px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
//   transition: all 0.2s ease;

//   &:hover {
//     transform: scale(1.1);
//   }

//   .anticon {
//     font-size: 24px;
//     color: rgb(43, 46, 72);
//   }
// `;

// const ChatbotIcon = styled(WechatFilled)`
//   font-size: 50px;
//   color: rgb(43, 46, 72);
//   cursor: pointer;
//   transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
//   transform: ${props => props.isChatbotOpen ? 'rotate(360deg) scale(1.1)' : 'rotate(0) scale(1)'};
  
//   &:hover {
//     transform: ${props => props.isChatbotOpen ? 'rotate(360deg) scale(1.2)' : 'scale(1.1)'};
//     filter: brightness(1.1);
//   }

//   animation: ${props => props.isChatbotOpen ? 'none' : 'bounce 2s infinite'};

//   @keyframes bounce {
//     0%, 20%, 50%, 80%, 100% {
//       transform: translateY(0);
//     }
//     40% {
//       transform: translateY(-12px);
//     }
//     60% {
//       transform: translateY(-6px);
//     }
//   }
// `;

  const iconStyles = {
    // display: "flex",
    position: "sticky",
    bottom: 10,
    right: 145,
    cursor: "pointer",
    zIndex: 1,
    float: "right",
    transition: "all 0.2s ease",
    // display: flex;
    // alignItems: "center",
    // background-color: none;
    // border-radius: 6px;
    height: "36px",
    // marginRight: "30px",
    // border: " 1px solid rgb(43, 46, 72)",
    marginRight: "15.5%",
    marginLeft: " 1.25%",
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  };
  if (isScrolled) {
    if (scrollOffSet < 30) {
      iconStyles.bottom = iconStyles.bottom + scrollOffSet + 3;
    } else {
      iconStyles.bottom = iconStyles.bottom + 33;
    }
    iconStyles.transform = "scale(1)";
  }

  const handleChatBotInput = (e) => {
    setChatbotInput(e.target.value);
  };

  const chatbotSubmit = async () => {
    if (!chatbotInput.trim()) return;
    
    setFormLoading(true);
    // NEW: Add this line
    setIsTyping(true); // Show typing indicator
    let payload = { prompt: chatbotInput };
  
    try {
      const response = await axios.post(
        "https://puebem38qc.execute-api.ap-south-1.amazonaws.com/dev/chatbot",
        payload,
        {
          "Content-Type": "application/json",
        }
      );
  
      const updatedFormSchema = response.data.body.message.form_schema.map(
        (field) => ({
          ...field,
          id: uuidv4(),
        })
      );
      
      setFormItems(updatedFormSchema);
      setPreviewMode(!previewMode);
      setChatbotInput('');
      setIsChatbotOpen(false);
      
    } catch (error) {
      toast.error("Sorry! Unexpected Error occurs");
    } finally {
      setIsTyping(false); 
      setFormLoading(false);
      // NEW: Add this line
      // Hide typing indicator
    }
  };

  const viewCreatedForms = () => {
    navigate("/view-forms");
  };

  const generateHtmlString = () => {
    return formItems
      .map((item) => {
        switch (item.field_type) {
          case "text":
            return `<Form.Item label="${item.label}"labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} name="${item.label}">
                <Input placeholder="${item.placeholder}" style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}/>
              </Form.Item>`;
          case "email":
            return `
              <Form.Item name="${item.label}" label="${item.label}">
                <Input placeholder="${item.placeholder}" />
              </Form.Item>`;
          case "number":
            return `
              <Form.Item label="${item.label}" name="${item.label}">
                <InputNumber
                  placeholder="${item.placeholder}"
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>`;
          case "password":
            return `
              <Form.Item label="${item.label}" name="${item.label}">
                <Input.Password placeholder="${item.placeholder}" />
              </Form.Item>`;
          case "checkbox":
            return `
            <Form.Item label="${item.label}">
                <Checkbox.Group>
                  ${item.options
                    .map(
                      (option, index) =>
                        `<Checkbox value=${option}>${option}</Checkbox>`
                    )
                    .join("")}
                </Checkbox.Group>
              </Form.Item>
      `;
          case "radio":
            return `
              <Form.Item label="${item.label}">
                <Radio.Group>
                  ${item.options
                    .map(
                      (option, index) =>
                        ` <Radio value=${option}>${option} </Radio>`
                    )
                    .join("")}
                </Radio.Group>
              </Form.Item>`;
          case "dropdown":
            return `
              <Form.Item label="${item.label}" name="${item.label}">
                <Select
                  placeholder="${item.placeholder}"
                  filterSort=${(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                      .join("")}
                  options=${item.options
                    .map((option, index) => ({
                      value: option,
                      label: option,
                    }))
                    .join("")}
                />
              </Form.Item>`;
          case "date":
            return `
              <Form.Item label="${item.label}" name="${item.label}">
                <DatePicker />
              </Form.Item>`;
          case "textarea":
            return `
              <Form.Item label="${item.label}">
                <TextArea rows={4} placeholder="${item.placeholder}" />
              </Form.Item>`;
          case "file":
            // return <input type="file" disabled={true} />;
            return `
              <Form.Item
                label="${item.label}"
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload action="/upload.do" listType="picture-card" maxCount={1}>
                  <button
                    style={{
                      border: 0,
                      background: "none",
                      
                    }}
                    type="button"
                  >
                    <PlusOutlined />
                    <div
                      style={{
                        marginTop: 5,
                      }}
                    >
                      Upload
                    </div>
                  </button>
                </Upload>
              </Form.Item>`;
          case "button":
            return `
              <Form.Item
                wrapperCol={{
                  offset: 6,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit" >
                  "${item.placeholder}"
                </Button>
              </Form.Item>
            ;`;
          default:
            return <div>Unknown Form Item</div>;
        }
      })
      .join("");
  };

  const handleCopyHtml = () => {
    const htmlString = generateHtmlString();
    navigator.clipboard.writeText(htmlString).then(() => {
      alert("HTML copied to clipboard!");
    });
  };
  const formTitleChange = (e) => {
    setFormTitle(e.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (chatbotInput.length > 0) {
        chatbotSubmit();
      }
    }
  };
  const addItemToForm = (item) => {
    const newItem = {
      id: uuidv4(),
      field_type: item.type,
      label: item.label,
      required: false,
      options:
        item.type === "dropdown" || "radio" || "checkbox"
          ? ["Option 1", "Option 2", "Option 3"]
          : [],

      placeholder: item.placeholder,
    };
    setFormItems((prevItems) => [...prevItems, newItem]);
  };

  const saveForm = async () => {
    if (!formTitle) {
      console.error("Please provide a title for your form.");
      toast.error("Please provide a title for your form.");
      // alert("Please provide a title for your form!");
      return; // Prevent saving if title is null
    }
    const payload = {
      title: formTitle,
      form_schema: formItems.map((item) => ({
        field_type: item.field_type,
        label: item.label,
        placeholder: item.placeholder,
        required: item.required,
        options: item.options,
      })),
    };

    try {
      console.log("payload.form_fields:", payload);
      // await createForm(payload.form_fields);
      const response = await axios.post(
        "https://qyow74zw35.execute-api.ap-south-1.amazonaws.com/dev/survey-form",
        payload,
        {
          "Content-Type": "application/json",
        }
      );
      console.log("response:", response);
      toast.success("Form and fields saved successfully");
      // alert("Form and fields saved successfully!");
    } catch (error) {
      toast.error("Error saving form.");
      // alert("Error saving form.");
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <BreadCrumbs location={props.location} />{" "}
      <ToastContainer position="top-center" />
      <div
        style={{
          marginBottom: "55px",
          display: "flex",
          backgroundColor: "white",
          minHeight: "50vh",
        }}
      >
        {/* Add Toolbox */}
        <div
          ref={drop}
          style={{
            //padding: "10px 16px 10px 16px",
           padding:'8px 14px',
           // minHeight: "433px",
           minHeight:'433px',
           //maxHeight:'570px',
          // height:'570px',

            flexGrow: 1,
            margin: "10px",
            border: "1px solid rgb(43, 46, 74)",
            borderRadius: "8px",
           // marginRight:" ${props => props.isChatOpen ? '400px' : '10px'}",
           width: "${props => props.isToolboxOpen ? 'calc(100% - 350px)' : 'calc(100% - 60px)'}",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: "#2B2E48",
              marginLeft: "20px",
            }}
          >
            <h3 style={{fontSize:'18px',transition:'all 0.2s ease ',backgroundColor:'white',fontWeight:'500'}}>{previewMode ? "Form Preview" : "Form Builder"}</h3>
            <div>
              <Input placeholder="Form Title" style={{width: '100%',
                padding: '12px 16px',
                fontSize: '18px',
                fontWeight: '500',
                border: '2px solid #e5e7eb',
                borderRadius: '8px',
                transition: 'all 0.2s ease',
                backgroundColor: 'white',
                color: '#1f2937',':hover': {
      borderColor: '#3b82f6'
    }}}onMouseEnter={(e) => e.target.style.borderColor = '#3b82f6'}
    onMouseLeave={(e) => e.target.style.borderColor = '#e5e7eb'} onChange={formTitleChange} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <button
                onClick={() => setPreviewMode(!previewMode)}
                style={{
                  marginRight: "8px",
                  padding: "8px 16px",
                  // backgroundColor: previewMode ? "#6c757d" : "#007bff",
                  backgroundColor: previewMode ? "#6c757d" : "#2B2E48",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon icon={faEye} style={{ marginRight: "8px" }} />
                {previewMode ? "Back to Edit" : "Preview Form"}
              </button>
              {/* <button
              onClick={viewCreatedForms}
              style={{
                padding: "8px 16px",
                backgroundColor: "#5046e5",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                marginRight: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon
                icon={faFileAlt}
                style={{ marginRight: "8px" }}
              />
              View Created Forms
            </button> */}
              {previewMode && (
                <>
                  <button
                    onClick={saveForm}
                    style={{
                      padding: "8px 16px",
                      backgroundColor: "#28a745",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                      marginRight: "8px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faSave}
                      style={{ marginRight: "8px" }}
                    />
                    Save Form
                  </button>
                  <button
                    onClick={handleCopyHtml}
                    style={{
                      padding: "8px 16px",
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCopy}
                      style={{ marginRight: "8px" }}
                    />
                    Copy as HTML
                  </button>
                </>
              )}
            </div>
          </div>
          <hr style={{ color: "rgb(43, 46, 74)" }} />
          {formLoading && (
            <div style={{ placeItems: "center", marginTop: "140px" }}>
              <LoadingSpinner />
              <div
                style={{
                  color: "rgb(43, 46, 74)",
                  alignSelf: "center",
                  fontWeight: "500",
                }}
              >
                Loading...
              </div>
            </div>
          )}
          {!formLoading && (
            <Form layout="vertical">
              {formItems.map((item, index) => (
                <FormField
                  style={{ width: "100px"  }}
                  key={index}
                  field={item}
               
                  previewMode={previewMode}
                  onDelete={() =>
                    setFormItems(formItems.filter((i) => i.id !== item.id))
                  }
                  onUpdateLabel={(newLabel) =>
                    setFormItems(
                      formItems.map((i) =>
                        i.id === item.id ? { ...i, label: newLabel } : i
                      )
                    )
                  }
                  onUpdatePlaceholder={(newPlaceholder) =>
                    setFormItems(
                      formItems.map((i) =>
                        i.id === item.id
                          ? { ...i, placeholder: newPlaceholder }
                          : i
                      )
                    )
                  }
                  onUpdateOptions={(newOptions) =>
                    setFormItems(
                      formItems.map((i) =>
                        i.id === item.id ? { ...i, options: newOptions } : i
                      )
                    )
                  }
                />
              ))}
            </Form>

          //   <FormContainer>
          //   <StyledForm layout="vertical">
          //     {formItems.length === 0 ? (
          //       <EmptyState>
          //         Drag and drop form fields here to get started
          //       </EmptyState>
          //     ) : (
          //       <div style={{ padding: "0 20px 20px" }}>
          //         {formItems.map((item, index) => (
          //           <div 
          //             key={item.id}
          //             style={{ 
          //               backgroundColor: "#f8f9fa",
          //               padding: "5px",
          //               borderRadius: "10px",
          //               marginBottom: "20px",
          //               border: "1px solid #e9ecef",
          //               position: "relative"
          //             }}
          //           >
          //             <label style={{
          //               display: "block",
          //               marginBottom: "10px",
          //               color: "#2B2E48",
          //               fontWeight: "500",
          //               fontSize: "13px"
          //             }}>
          //               {item.label}
          //             </label>
          //             <FormField
          //               field={item}
          //               previewMode={previewMode}
          //               onDelete={() => setFormItems(formItems.filter(i => i.id !== item.id))}
          //               onUpdateLabel={(newLabel) => 
          //                 setFormItems(formItems.map(i => 
          //                   i.id === item.id ? { ...i, label: newLabel } : i
          //                 ))
          //               }
          //               onUpdatePlaceholder={(newPlaceholder) =>
          //                 setFormItems(formItems.map(i =>
          //                   i.id === item.id ? { ...i, placeholder: newPlaceholder } : i
          //                 ))
          //               }
          //               onUpdateOptions={(newOptions) =>
          //                 setFormItems(formItems.map(i =>
          //                   i.id === item.id ? { ...i, options: newOptions } : i
          //                 ))
          //               }
          //             />
          //           </div>
          //         ))}
          //       </div>
          //     )}
          //   </StyledForm>
          // </FormContainer>
          )}
        </div>
        <Toolbox onToggle={handleToolboxToggle} />
      </div>
      <div
        style={{
          position: "relative",
          bottom: 10,
          right: 145,
          cursor: "pointer",
          zIndex: 1,
          transition: "all 0.2s ease",
          display: "flex",
          marginLeft: "200px"
          //bottom:" ${props => props.isOpen ? '80px' : '20px'}",
        }}
      >
{/* {!isChatbotOpen && <div style={{ width:'100%'}}></div>}
        {isChatbotOpen && (
          <>
            <div className="input-container" style={{width:'73%', marginBottom: "80px"}} >
              <Input
                onChange={(e) => handleChatBotInput(e.target.value)}
                style={{ height: "100%" }}
                name="chatbot"
                onKeyDown={handleKeyPress}
                value={chatbotInput}
                placeholder="Tell me about your form, I will suggest you"
              />
              <Button
                type="link"
                onClick={() => (chatbotInput.length > 0 ? chatbotSubmit() : "")}
                icon={
                  <SendOutlined
                    style={{ fontSize: "23px", color: "rgb(43, 46, 72)" }}
                  />
                }
              />
            </div>
          </>
        )} */}

         {isChatbotOpen && (
        <ChatModal>
          <ChatHeader>
            <h3 style={{ margin: 0, fontSize: '18px' }}>Form Assistant</h3>
            <CloseOutlined
              style={{ cursor: 'pointer', fontSize: '16px' }}
              onClick={() => setIsChatbotOpen(false)}
            />
          </ChatHeader>
          <ChatArea>
            <WelcomeMessage>
              Hello! How can I help you today?
            </WelcomeMessage>
            {isTyping && (
      <TypingIndicatorContainer>
        <TypingDot />
        <TypingDot />
        <TypingDot />
      </TypingIndicatorContainer>
    )}
          </ChatArea>
          <ChatInputContainer>
          <Input
               onChange={(e) => setChatbotInput(e.target.value)}  // Changed this line
              placeholder="Tell me about your form, I will suggest you"
              value={chatbotInput}
              onKeyDown={handleKeyPress}
              style={{ flex: 1 }}
              autoFocus
            />
            <Button
              type="link"
              onClick={() => (chatbotInput.length > 0 ? chatbotSubmit() : "")}
              icon={
                <SendOutlined
                  style={{ fontSize: "23px", color: "rgb(43, 46, 72)" }}
                />
              }
            />
          </ChatInputContainer>
        </ChatModal>
      )}
 
      <div style={{ position: "relative", bottom: 40, right: 145, cursor: "pointer", zIndex: 1000, transition: "all 0.2s ease",marginLeft:'200px', position: 'fixed',
  }}>
        <BouncingChatButton
          onClick={() => setIsChatbotOpen(!isChatbotOpen)}
        />
      </div>
      </div>
        {/* <WechatFilled
          style={{
            float: "right",
            fontSize: "50px",
            color: "rgb(43, 46, 72)",
            margin: "-6px 150px 0px 0px",
          }}
          // onClick={() => {
          //   setIsChatbotOpen(!isChatbotOpen);
          // }}
          className="chat-icon"
        /> */}
  {/* <div style={{ position: "relative", bottom: 40, right: 145, cursor: "pointer", zIndex: 1000, transition: "all 0.2s ease",marginLeft:'200px', position: 'fixed',
  }}>
<BouncingChatButton
          onClick={() => {
            setIsChatbotOpen(!isChatbotOpen);
          }}
        />
        </div> */}
      
      {/* <div style={iconStyles}>
        <div style={{ right: 110 }}>
          <WechatFilled
            style={{
              fontSize: "50px",
              color: "rgb(43, 46, 72)",
            }}
            onClick={() => {
              setIsChatbotOpen(!isChatbotOpen);
            }}
            className="chat-icon"
          />
        </div>
      </div> */}
      </div>
  );
};

export default FormBuilder;
